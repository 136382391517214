<template>
  <el-card shadow="never">
    <template #header>
      <i class="el-icon-tickets"></i>&nbsp;
      <span>分类页管理</span>
    </template>

    <div class="content">
      <div class="content-header">
        <el-row>
          <el-col>
            <el-button type="success" class="f-right" @click="cateAddRoot()"
              >新增分类</el-button
            >
          </el-col>
        </el-row>
      </div>

      <div class="content-main">
        <div class="tree-head">
          <div class="tree-head-one">分类名称</div>
          <div class="tree-head-two">链接</div>
          <div class="tree-head-three">标签</div>
          <div class="tree-head-three">状态</div>
          <div class="tree-head-control">操作</div>
        </div>
        <el-tree
          :data="treeTableData"
          ref="treeTable"
          :props="treeDefaultProps"
          :default-expand-all="true"
          :expand-on-click-node="false"
        >
          <template #default="{ node, data }">
            <div class="tree-custom-node">
              <span class="cate_name">
                <span v-if="data.icon" class="thumb">
                  <el-image class="cate-thumb" :src="data.icon" /> </span
                >{{ data.title }}</span
              >
              <span class="tree-head-two" show-overflow-tooltip>{{
                data.linkname
              }}</span>
              <span class="tree-head-three">{{ data.tag }}</span>
              <span class="tree-head-three">
                <label v-if="data.status * 1 == 1" style="color: green"
                  >已启用</label
                >
                <label v-else-if="data.status * 1 == 0" style="color: red"
                  >已停用</label
                >
              </span>

              <span v-if="data.anchor" class="cate_control">
                <el-button
                  v-if="data.ad && data.ad.linktype == 'goodslist'"
                  type="text"
                  @click.stop="selGoods('ad', node, data)"
                  >商品管理</el-button
                >
                <el-button type="text" @click.stop="cateEditAD(node, data)"
                  >广告</el-button
                >
                <el-button
                  type="text"
                  @click.stop="addChildren('root', node, data)"
                  >添加子分类</el-button
                >
                <el-button
                  type="text"
                  @click.stop="cateUpdateStatus('root', node, data)"
                  :class="data.status * 1 == 1 ? 'delete' : ''"
                  >{{ data.status * 1 == 0 ? "启用" : "停用" }}</el-button
                >
                <el-button type="text" @click.stop="cateAddRoot(node, data)"
                  >编辑</el-button
                >
                <el-button type="text" @click="cateMoveUp('root', node, data)"
                  >上移</el-button
                >
                <el-button type="text" @click="cateMoveDown('root', node, data)"
                  >下移</el-button
                >
                <el-button
                  class="delete"
                  type="text"
                  @click.stop="cateRemove('root', node, data)"
                  >删除</el-button
                >
              </span>

              <span v-else class="cate_control">
                <el-button
                  type="text"
                  @click.stop="selGoods('children', node, data)"
                  >商品管理</el-button
                >
                <el-button
                  type="text"
                  :class="data.status * 1 == 1 ? 'delete' : ''"
                  @click.stop="cateUpdateStatus('children', node, data)"
                  >{{ data.status * 1 == 0 ? "启用" : "停用" }}</el-button
                >
                <el-button
                  type="text"
                  @click.stop="addChildren('children', node, data)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="cateMoveUp('children', node, data)"
                  >上移</el-button
                >
                <el-button
                  type="text"
                  @click="cateMoveDown('children', node, data)"
                  >下移</el-button
                >
                <el-button
                  class="delete"
                  type="text"
                  @click.stop="cateRemove('children', node, data)"
                  >删除</el-button
                >
              </span>
            </div>
          </template>
        </el-tree>
      </div>

      <el-button type="primary" @click="publishPage()" style="margin-top: 20px"
        >保存页面设置</el-button
      >
    </div>
  </el-card>

  <el-dialog
    v-if="editroot"
    title="主分类编辑器"
    v-model="editroot"
    @close="onCloseChilren()"
    width="40%"
  >
    <el-form
      :model="curroot"
      label-width="100px"
      class="dataform"
      :rules="rules.root"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curroot.title" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onCloseChilren">取 消</el-button>
        <el-button type="primary" @click="cateSaveRoot()"
          >保存主菜单项</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-if="editad"
    title="分类广告编辑器"
    v-model="editad"
    @close="onCloseChilren()"
    width="40%"
  >
    <el-form
      :model="curad"
      label-width="100px"
      class="dataform"
      :rules="rules.ad"
      ref="curad"
    >
      <el-form-item label="广告图片" prop="img">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="cateImageUploadSuccess"
          :on-change="cateImageUploadChange"
          :on-error="cateImageUploadError"
          :show-file-list="false"
          ref="cateImageUpload"
        >
          <img
            v-if="curad.img"
            :src="curad.img"
            class="avatar"
            :preview-src-list="[curad.img]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >广告图片最佳尺寸：500px × 180px</el-tag
        >
      </el-form-item>
      <el-form-item label="链接">
        <el-select v-model="curad.linktype" @change="cateChangeLinkType">
          <el-option
            v-for="item in linktype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          v-if="
            curad.linktype &&
            curad.linktype != 'notlink' &&
            curad.linktype != 'goodslist' &&
            curad.linktype != 'link'
          "
          class="tab-header-contorl"
        >
          <el-input
            v-model="curad.linkname"
            autocomplete="off"
            readonly="true"
            class="readinput"
          ></el-input>
          <el-button type="success" @click="cateSelLink" class="sel-btn"
            >选择</el-button
          >
        </div>
        <div v-else-if="curad.linktype == 'link'" class="tab-header-contorl">
          <el-input
            v-model="curad.link"
            autocomplete="off"
            class="readinput"
            placeholder="请输入链接地址"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="danger" v-if="curad.img" @click="cateResetAD()"
          >重置分类广告</el-button
        >
        <el-button @click="onCloseChilren">取 消</el-button>
        <el-button type="primary" @click="cateSaveAD()">保存分类广告</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-if="editchildren"
    title="子分类编辑器"
    v-model="editchildren"
    @close="onCloseChilren()"
    width="40%"
  >
    <el-form
      :model="curchildren"
      label-width="100px"
      class="dataform"
      :rules="rules.children"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curchildren.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="导航图标" prop="icon">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="cateImageUploadSuccess"
          :on-change="cateImageUploadChange"
          :on-error="cateImageUploadError"
          :show-file-list="false"
          ref="cateImageUpload"
        >
          <img
            v-if="curchildren.icon"
            :src="curchildren.icon"
            class="avatar"
            :preview-src-list="[curchildren.icon]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >导航图标最佳尺寸：180px × 180px</el-tag
        >
      </el-form-item>
      <el-form-item label="标签" prop="tag">
        <el-input v-model="curchildren.tag" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onCloseChilren">取 消</el-button>
        <el-button type="primary" @click="cateSaveChildren()"
          >保存子分类</el-button
        >
      </span>
    </template>
  </el-dialog>

  <LinkBlock
    :v-if="cateshowlink"
    :linktype="catelinktype"
    :showlink="cateshowlink"
    :curgoodsid="categoodsid"
    :curlink="catelink"
    v-on:close="onClose"
    v-on:submit="setLink"
  ></LinkBlock>
</template>

<script>
import pageconst from "@/pageconst";
import { ElMessage, ElMessageBox } from "element-plus";
import LinkBlock from "@/components/LinkBlock";

export default {
  components: {
    LinkBlock,
  },
  data() {
    return {
      uploadAction: pageconst.upload_pdt_detail_url,
      config: {},
      treeTableData: [],
      treeDefaultProps: {
        children: "children",
        label: "CategoryName",
      },
      newrootid: 0,
      newchildrenid: 0,
      curroot: {},
      orgroot: {},
      editroot: false,
      curad: {},
      orgad: {},
      editad: false,
      curchildren: {},
      orgchilren: {},
      editchildren: false,
      currootindex: "",
      curindex: "",
      rules: {
        ad: {
          img: [
            { required: true, message: "请上传广告图片", trigger: "blur" },
          ]
        },
        root: {
          title: [
            {
              required: true,
              message: "请输入主分类标题",
              trigger: "blur",
            },
          ],
        },
        children: {
          title: [
            {
              required: true,
              message: "请输入子分类标题",
              trigger: "blur",
            },
          ],
          icon: [
            { required: true, message: "请上传子分类图标", trigger: "blur" },
          ],
        },
      },

      linktype: [
        {
          value: "notlink",
          label: "不跳转",
        },
        {
          value: "page",
          label: "页面跳转",
        },
        {
          value: "goods",
          label: "商品",
        },
        {
          value: "goodslist",
          label: "商品集合页",
        },
        {
          value: "link",
          label: "链接",
        },
      ],
      catelinktype: "",
      categoodsid: "",
      catelink: "",
      cateshowlink: false,
    };
  },
  methods: {
    publishPage() {
      // 发布页面、检测是否符合条件，不符合则提示相应错误信息
      console.log("发布页面");
      this.axios
        .get(pageconst.pagemanage + "?entry=publish&pagename=category")
        .then((response) => {
          if (response.data == "SUCCESS") {
            ElMessage({
              message: "保存成功",
              type: "success",
            });
          } else {
            ElMessage.error({
              showClose: true,
              message: response.data,
            });
            return false;
          }
        });
    },

    getNextID() {
      let maxid = 0,
        curid = 0;
      for (let i = 0; i < this.treeTableData.length; i++) {
        curid = this.treeTableData[i].id * 1;
        if (maxid < curid) {
          maxid = curid;
        }

        let curchildren = this.treeTableData[i].children;
        for (let j = 0; j < curchildren.length; j++) {
          curid = curchildren[j].id * 1;
          if (maxid < curid) {
            maxid = curid;
          }
        }
      }
      console.log(maxid);
      return maxid + 1;
    },
    getCurIndex(node, data) {
      let parent = node.parent;
      let children = parent.data.children || parent.data;
      let index = children.findIndex((d) => d.id === data.id);

      return index;
    },

    cateImageUploadChange(file) {
      let isSubmit = file.raw.size / 1024 / 1024 < 2;
      if (!isSubmit) {
        this.$message.error("图片大小不能超过 2MB!");
        return false;
      }

      if (isSubmit) this.$refs.cateImageUpload.submit();
    },
    cateImageUploadSuccess(res) {
      console.log("image upload success ===> " + res.url);
      if (this.editad) {
        this.curad.img = res.url;
      } else {
        this.curchildren.icon = res.url;
      }
    },
    cateImageUploadError(err) {
      console.log("err==>");
      console.log(err);
    },

    onClose(isclose) {
      this.cateshowlink = isclose;
    },
    setLink(link, linkname, goodsid = "") {
      if (this.editad) {
        this.curad.link = link;
        this.curad.linkname = linkname;
        this.curad.goodsid = goodsid;
      } else if (this.editchildren) {
        this.curchildren.link = link;
        this.curchildren.linkname = linkname;
        this.curchildren.goodsid = goodsid;
      }
    },
    cateSelLink() {
      let curitem = {};
      if (this.editad) curitem = this.curad;
      else if (this.editchildren) curitem = this.curchildren;

      this.catelinktype = curitem.linktype;
      this.categoodsid = curitem.goodsid;
      this.catelink = curitem.link;
      this.cateshowlink = true;
    },
    cateChangeLinkType(e) {
      this.catelinktype = e;
      let linkname = "";
      if (e != "notlink" && e != "goodslist" && e != "link") {
        this.cateshowlink = true;
      } else if (e == "goodslist") {
        linkname = "商品集合页";
      } else if (e == "link") {
        linkname = "自定义链接";
      } else {
        linkname = "不跳转";
      }

      if (this.editad) this.curad.linkname = linkname;
      else if (this.editchildren) this.curchildren.linkname = linkname;

      console.log(this.cateshowlink);
    },

    cateUpdateStatus(type, node, data) {
      let index = this.getCurIndex(node, data),
        curstatus = 0,
        title = "",
        rootindex = 0;
      if (type == "root") {
        curstatus = this.treeTableData[index].status;
        title = this.treeTableData[index].title;

        if (curstatus == 0) this.treeTableData[index].status = 1;
      } else if (type == "children") {
        rootindex = this.getCurIndex(node.parent, node.parent.data);
        curstatus = this.treeTableData[rootindex].children[index].status;
        title = this.treeTableData[rootindex].children[index].title;

        if (curstatus == 0)
          this.treeTableData[rootindex].children[index].status = 1;
      }

      if (curstatus == 1) {
        // 询问
        let that = this;
        ElMessageBox.confirm("您确定要停用 " + title + " 吗？", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (type == "root") {
              this.treeTableData[index].status = 0;
            } else if (type == "children") {
              this.treeTableData[rootindex].children[index].status = 0;
            }

            that.cateSaveConfig();
          })
          .catch((error) => {
            console.log("取消操作", error);
          });
      } else {
        this.cateSaveConfig();
      }
    },

    cateSwapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    cateMoveUp(type, node, data) {
      let index = this.getCurIndex(node, data);
      //console.log("up", children);

      if (index != 0) {
        if (type == "root") {
          this.treeTableData = [
            ...this.cateSwapArray(this.treeTableData, index, index - 1),
          ];
        } else if (type == "children") {
          // 移动子分类
          let rootindex = this.getCurIndex(node.parent, node.parent.data);

          let ttd = this.treeTableData;
          ttd[rootindex].children = this.cateSwapArray(
            ttd[rootindex].children,
            index,
            index - 1
          );
          this.treeTableData = [...ttd];
        }

        this.cateSaveConfig();
      }
    },
    cateMoveDown(type, node, data) {
      let index = this.getCurIndex(node, data);

      console.log("down", type, parent, index);

      if (type == "root") {
        if (index + 1 != this.treeTableData.length) {
          this.treeTableData = [
            ...this.cateSwapArray(this.treeTableData, index, index + 1),
          ];
        }
      } else if (type == "children") {
        // 移动子分类
        let rootindex = this.getCurIndex(node.parent, node.parent.data);
        let ttd = this.treeTableData;

        console.log("down children ", ttd[rootindex].children);

        if (index + 1 != ttd[rootindex].children.length) {
          ttd[rootindex].children = this.cateSwapArray(
            ttd[rootindex].children,
            index,
            index + 1
          );
          this.treeTableData = [...ttd];
        }
      }

      this.cateSaveConfig();
    },
    cateRemove(type, node, data) {
      let that = this;
      let index = this.getCurIndex(node, data);

      ElMessageBox.confirm("您确定要删除 " + data.title + " 吗？", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == "root") {
            let ttd = that.treeTableData;
            ttd.splice(index, 1);
            that.treeTableData = [...ttd];
          } else if (type == "children") {
            let rootindex = that.getCurIndex(node.parent, node.parent.data);
            console.log(rootindex);
            let ttd = that.treeTableData;
            ttd[rootindex].children.splice(index, 1);
            that.treeTableData = [...ttd];
          }

          that.cateSaveConfig();
        })
        .catch((error) => {
          console.log("取消操作", error);
        });
    },
    cateSaveConfig() {
      this.config.category.value = [...this.treeTableData];
      //console.log(this.config);
      this.axios
        .post(
          pageconst.pagemanage + "?entry=saveconfig&pagename=category",
          JSON.stringify(this.config),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data == "SUCCESS") {
            console.log("保存成功");
          } else {
            ElMessage.error({
              showClose: true,
              message: response.data,
            });
            return false;
          }
        });
    },

    selGoods(type, node, data) {
      let index = -1,
        rootindex = -1;
      if (type == "ad") {
        rootindex = this.getCurIndex(node, data);
      } else if (type == "children") {
        index = this.getCurIndex(node, data);
        rootindex = this.getCurIndex(node.parent, node.parent.data);
      }

      this.$router.push({
        path: "/page/goodsmanage",
        query: { pagename: "category", rootindex: rootindex, index: index },
      });
    },

    cateAddRoot(node = "", data = "") {
      if (node !== "" && data !== "") {
        // 编辑根分类
        this.currootindex = this.getCurIndex(node, data);
        this.curroot = data;
        this.orgroot = JSON.stringify(data);
      } else {
        this.currootindex = "";
        // 新增根分类
        this.newrootid = this.getNextID();

        this.curroot = {};
        this.curroot.id = this.getNextID();
        this.curroot.ad = {};
        this.curroot.anchor = "cate_" + this.curroot.id;
        this.curroot.children = [];

        this.orgroot = JSON.stringify({});
      }
      this.editroot = true;
    },
    cateSaveRoot() {
      this.$refs["dataform"].validate((res) => {
        if (res) {
          if (this.currootindex !== "") {
            this.treeTableData[this.currootindex] = this.curroot;
          } else {
            this.treeTableData.push(this.curroot);
          }
          this.editroot = false;

          this.cateSaveConfig();
        }
      });
    },

    cateEditAD(node, data) {
      this.currootindex = this.getCurIndex(node, data);
      if (!data.ad) data.ad = {};
      this.curad = data.ad;
      if (!this.curad.linktype) this.curad.linktype = "notlink";
      if (!this.curad.linkname) this.curad.linkname = "";
      this.orgad = JSON.stringify(data.ad);

      this.editad = true;
    },
    cateSaveAD() {
      console.log(this.curad);
      this.$refs["curad"].validate((res) => {
        if (res) {
          this.treeTableData[this.currootindex].ad = this.curad;
          this.editad = false;

          this.cateSaveConfig();
        }
      });
    },
    cateResetAD() {
      let that = this;
      ElMessageBox.confirm("您确定要重置当前分类广告吗？", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.curad.img = "";
          that.curad.link = "";
          that.curad.linkname = "";
          that.curad.goodsid = "";
          that.curad.linktype = "notlink";
        })
        .catch(() => {
          console.log("取消操作");
        });
    },

    addChildren(type, node = "", data = "") {
      if (type == "root") {
        // 新增子分类
        this.curindex = "";
        this.currootindex = this.getCurIndex(node, data);
        this.curchildren = {};
        this.curchildren.id = this.getNextID();
      } else if (type == "children") {
        // 编辑子分类
        this.curindex = this.getCurIndex(node, data);
        this.currootindex = this.getCurIndex(node.parent, node.parent.data);
        this.curchildren = data;
      }

      if (!this.curchildren.linktype) this.curchildren.linktype = "notlink";
      if (!this.curchildren.linkname) this.curchildren.linkname = "";
      if (!this.curchildren.status) this.curchildren.status = 0;

      this.editchildren = true;
    },
    cateSaveChildren() {
      console.log(this.curchildren);
      this.$refs["dataform"].validate((res) => {
        if (res) {
          if (this.curindex !== "") {
            this.treeTableData[this.currootindex].children[this.curindex] =
              this.curchildren;
          } else {
            this.treeTableData[this.currootindex].children.push(
              this.curchildren
            );
          }
          this.editchildren = false;

          console.log(this.treeTableData);

          this.cateSaveConfig();
        }
      });
    },

    onCloseChilren() {
      if (this.editroot) {
        this.editroot = false;
        this.treeTableData[this.currootindex] = JSON.parse(this.orgroot);
      } else if (this.editad) {
        this.treeTableData[this.currootindex].ad = JSON.parse(this.orgad);
        this.editad = false;
      } else if (this.editchildren) {
        this.editchildren = false;
      }
    },
  },
  created() {
    this.axios
      .get(pageconst.pagemanage + "?entry=getconfig&pagename=category", {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => {
        var { config } = data;
        this.config = config;
        this.treeTableData = config.category.value;
        console.log(config);
      });
  },
};
</script>

<style scoped>
.tab-header-contorl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.sel-btn {
  margin-left: 10px;
}

.dataform:deep() .avatar-uploader {
  line-height: 0;
}

.dataform:deep() .avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.dataform:deep() .avatar {
  width: 100%;
  max-width: 400px;
  display: block;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

.cate_control .el-button {
  margin-left: 15px;
}
.cate_control .el-button:first-child {
  margin-left: 0;
}
.cate_control .el-button.delete {
  color: #f66;
}
.cate_control .el-button.green {
  color: #67c23a;
}

.tree-head {
  height: 48px;
  line-height: 48px;
  border: 1px solid #ebeef5;
  border-bottom: none;
  display: flex;
  font-weight: bold;
  color: #909399;
  font-size: 14px;
  margin-top: 20px;
}

.tree-head .tree-head-one {
  padding-left: 8px;
  flex-grow: 1;
}

.tree-head-two {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-head-three {
  width: 120px;
}

.tree-head .tree-head-control,
.el-tree .tree-custom-node .cate_control {
  width: 420px;
  padding-right: 8px;
  text-align: right;
}

.el-tree {
  overflow: hidden;
  border-bottom: 1px solid #ebeef5;
}

:deep() .el-tree-node__content {
  height: 48px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
}

.tree-custom-node {
  height: 100%;
  line-height: 48px;
  font-size: 14px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.el-tree .tree-custom-node .cate_name {
  flex-grow: 1;
  height: 48px;
}

.cate_form:deep() .input-size {
  width: 50%;
}

.thumb {
  height: 100%;
  padding: 4px 0;
  box-sizing: border-box;
  margin-right: 10px;
  display: block;
  float: left;
}

:deep() .cate-thumb {
  height: 100%;
  padding: 2px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 4px;
}

:deep() .cate-thumb img {
  width: auto;
}
</style>